<template>
    <layout-heading>
        <portal to="title">
            <img src="../assets/images/icon-QR.svg" alt="">
            <span>{{title}}</span>
        </portal>

        <Markdown v-if="content" class="kyc-landing-content" :content="content" :data="organization"/>

        <div v-if="this.loggedIn" class="text-center">
            <h5>You are already logged in, click Begin to continue</h5>
            <button class="btn btn-primary begin-kyc" @click="continueKyc">Begin</button>
        </div>
        <div v-else>
            <h2 class="kyc-landing-register">Log In Or Register To Begin</h2>
            <p class="kyc-landing-enter-email">Enter your email address and choose a password</p>
            <div class="kyc-landing-form">
                <div class="row align-items-start no-gutters">
                    <div class="col-md-7 content-column">
                        <LoginForm @login="continueKyc"/>
                    </div>
                    <div class="col-md-4 sidebar">
                        <p>
                            If you already have an account, simply enter your existing email and password.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </layout-heading>
</template>

<script>
    import LoginForm from "@/components/LoginForm";
    import Markdown from "@/components/Markdown";
    import {mapGetters} from "vuex";

    export default {
        components: {LoginForm, Markdown},
        data() {
            return {
                title: "KYC",
                content: null,
                organization: null
            };
        },
        computed: {
            ...mapGetters('user', ['api', "loggedIn"]),
        },
        created() {
            this.api.getOrganization(this.$route.params.org || "blockwell")
                .then(org => {
                    org.organization = org.displayName;
                    this.organization = org;
                    if (org.landing) {
                        if (org.landing.content) {
                            this.content = org.landing.content;
                        }
                        if (org.landing.title) {
                            this.title = org.landing.title;
                            document.title = org.landing.title + " | Blockwell Wallet";
                        }
                    }  else {
                        this.content = `# Know Your Customer (KYC)

To comply with financial regulations, some information will be needed
from you.

Once you've finished KYC, {{organization}} will be notified and they
can continue the process.
`;
                    }
                });
        },
        methods: {
            continueKyc() {
                this.api.addOrganization(this.organization.name)
                    .then(org => {
                        if (org.landing && org.landing.redirect === 'external') {
                            this.$router.push('/app/account/external');
                        } else {
                            this.$router.push('/app/account/kyc');
                        }
                    });
            }

        }
    }
</script>

<style scoped>

</style>
